@import "./mixins.pcss";

.mask {
    width: 100%;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 3000;
    transition: .6s;
    display: flex;
    justify-content: center;
    align-items: center;

    & .loader {

        .cssload-thecube {
            width: 62px;
            height: 62px;
            margin: 0 auto;
            margin-top: 41px;
            position: relative;
            transform: rotateZ(45deg);
        }

        .cssload-thecube .cssload-cube {
            position: relative;
            transform: rotateZ(45deg);
            float: left;
            width: 50%;
            height: 50%;
            position: relative;
            transform: scale(1.1);
        }

        .cssload-thecube .cssload-cube::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(177,198,27);
            animation: cssload-fold-thecube 2.4s infinite linear both;
        }

        .cssload-thecube .cssload-c2 {
            transform: scale(1.1) rotateZ(90deg);
        }

        .cssload-thecube .cssload-c3 {
            transform: scale(1.1) rotateZ(180deg);
        }

        .cssload-thecube .cssload-c4 {
            transform: scale(1.1) rotateZ(270deg);

        }

        .cssload-thecube .cssload-c2::before {
            animation-delay: .3s;
        }

        .cssload-thecube .cssload-c3::before {
            animation-delay: .6s;

        }

        .cssload-thecube .cssload-c4::before {
            animation-delay: .9s;

        }

        @keyframes cssload-fold-thecube {

            0%, 10% {
                transform: perspective(115px) rotateX(-180deg);
                opacity: 0;
            }

            25%,
            75% {
                transform: perspective(115px) rotateX(0deg);
                opacity: 1;
            }

            90%,
            100% {
                transform: perspective(115px) rotateY(180deg);
                opacity: 0;
            }
        }

        @keyframes cssload-fold-thecube {

            0%, 10% {
                -o-transform: perspective(115px) rotateX(-180deg);
                opacity: 0;
            }

            25%,
            75% {
                -o-transform: perspective(115px) rotateX(0deg);
                opacity: 1;
            }

            90%,
            100% {
                -o-transform: perspective(115px) rotateY(180deg);
                opacity: 0;
            }
        }

        @keyframes cssload-fold-thecube {

            0%, 10% {
                -ms-transform: perspective(115px) rotateX(-180deg);
                opacity: 0;
            }

            25%,
            75% {
                -ms-transform: perspective(115px) rotateX(0deg);
                opacity: 1;
            }

            90%,
            100% {
                -ms-transform: perspective(115px) rotateY(180deg);
                opacity: 0;
            }
        }
        
        @keyframes cssload-fold-thecube {

            0%, 10% {
                -webkit-transform: perspective(115px) rotateX(-180deg);
                opacity: 0;
            }

            25%,
            75% {
                -webkit-transform: perspective(115px) rotateX(0deg);
                opacity: 1;
            }

            90%,
            100% {
                -webkit-transform: perspective(115px) rotateY(180deg);
                opacity: 0;
            }
        }

        @keyframes cssload-fold-thecube {

            0%, 10% {
                -moz-transform: perspective(115px) rotateX(-180deg);
                opacity: 0;
            }

            25%,
            75% {
                -moz-transform: perspective(115px) rotateX(0deg);
                opacity: 1;
            }

            90%,
            100% {
                -moz-transform: perspective(115px) rotateY(180deg);
                opacity: 0;
            }
        }
    }
}

.hide-load {
    opacity: 0;
}