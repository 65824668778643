@import "./mixins.pcss";

@import "./breakpoints.css";

.dropdown__menu {
    width: 189px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, .15);
    border-radius: 8px;
    opacity: 0;
    transition: all .2s linear, visibility 0s linear .2s;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
    position: absolute;
    z-index: 200;
    background: #fff;
    right: 0px;
    top: 33px;

    @media(--mobile) {
        top: -15px;
    }

    a {
        transition: background .2s linear;

        &:hover {
            background: var(--background);
        }
    }

    & a:first-child {
        padding: 24px 0 12px;

        &:hover {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    & a:last-child {
        padding: 12px 0 24px;

        &:hover {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    & .dropdown__item {
        font-weight: 600;
        font-size: var(--font-size);
        line-height: 18px;
        padding-left: 24px;
        display: flex;
        align-items: center;

        &-img {
            width: 24px;
            height: 24px;
            padding-right: 16px;
            transition: transform .2s ease-out;
        }
    }

    & .setting {

        & .dropdown__item-img {
            background: svg-load("../assets/img/icons/cog.svg" , fill= #8f8f8f);
            background-repeat: no-repeat;
        }
    }

    & .dropdown__item:hover > .dropdown__item-img {
        transform: scale(1.2);
    }

    & .exit {

        & .dropdown__item-img {
            background: svg-load("../assets/img/icons/exit.svg" , fill= #8f8f8f);
            background-repeat: no-repeat;
        }
    }
}

.show {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

