.block {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper {
    margin: 0 auto;
    width: 80%;
    gap: 55px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media(max-width: 64em) {
        grid-template-rows: .5fr 1fr;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
        column-gap: 15px;
    }
}

.container {
    text-align: center;

    @media(max-width: 64em) {
        grid-row: 2;
        grid-column: 1/ span 2;
    }

    &--left {
        background-image: svg-load("../../assets/img/404/destop-left.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;

        @media(max-width: 64em) {
            grid-row: 1;
            grid-column: 1 / 2;  
        }
    }

    &--right {
        background-image: svg-load("../../assets/img/404/desktop-right.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;

        @media(max-width: 64em) {
            grid-row: 1;
            grid-column: 2/ 3;  
        }
    }


    & .title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin: 25px 0;

        @media(max-width: 64em) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    & .text {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16vw;
        line-height: 17vw;
        color: #ffc840;
        margin-bottom: 40px;
    } 

    & .main-btn {
        padding: 1rem 2rem;

        @media(max-width: 30em) {
            padding: 11px 24px;
        }
    }

    & .logo {

        & img {
            width: auto;
        }
    }
}