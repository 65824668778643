/* mixins */

@define-mixin position-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@define-mixin border-bottom {
    border-bottom: 1px solid #e0e0e0;
}

@define-mixin box-shadow {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, .04), 2px 0px 8px rgba(0, 0, 0, .04), 8px 0px 32px rgba(0, 0, 0, .12);
}

@define-mixin common-block {
    background: #fff;
    box-shadow: 0 0 12px rgba(0,0,0,.06), 0 5px 10px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.24);
    border-radius: 8px;
    padding: 2.5rem 2rem;
    flex-wrap: wrap;

    @media(--mobile) {
        padding: 20px 16px;
    }
}

@define-mixin title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
    margin-top: 0;

    @media (--tablet) {
        font-size: 24px;
        line-heignpht: 22px;
        margin-bottom: 16px;
    }
}

@define-mixin subtitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* @media (--tablet) {
        font-size: 16px;
        line-height: 20px;
    } */
}

@define-mixin label-title {
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 6px;
}

@define-mixin input {
    border-radius: 24px;
    background: #fff;
    color: var(--inactive-color);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid var(--grey);
    padding: 12px 18px;

    @media (--mobile) {
        padding: 10px 8px;
        font-size: var(--font-size);
        line-height: 20px;
    }
}

@define-mixin payment-method-item {
    height: 80px;
    border: none;
    background: var(--background);
    border-radius: 8px;

    &:hover {
        background: #fff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, .1);

        & span {
            transform: scale(1.2);
        }
    }
}

@define-mixin payment-method-card {
    background-repeat: no-repeat;
    display: block;
    margin: auto;
    transition: 1s;
}
