@import "./mixins.pcss";

@import "./breakpoints.css";

.header {

    @mixin position-center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size);
    line-height: 18px;
    color: var(--main-color);
    /* padding: 14px 0; */
    /* width: 100%; */
    height: 72px;
    box-sizing: border-box;
 
    @media (--desktop) {
        border-bottom: 0;
        margin: 0;
        width: 100%;
    }

    & .inner-left {
        display: flex;
        width: auto;
        justify-content: space-between;
        /* margin-right: 48px; */

        & .header__support {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 48px;

            @media(--tablet) {
                margin-right: 0;
            }

            @media(--tablet) {
                display: none;
            }

            & span {
                display: block;
                white-space: nowrap;
                padding-left: 12px;
            }

            &:hover {
                color: var(--alt-color);
            }
        }

        & .header__tel {
            cursor: pointer;
            align-items: center;
            display: flex;

            @media(--tablet) {
                display: none;
            }

            & a {
                display: flex;
                align-items: center;
            }

            & span {
                display: block;
                white-space: nowrap;
                padding-left: 12px;

                @media (--tablet) {
                    display: none;
                }
            }

            &:hover {
                color: var(--alt-color);
                opacity: 1;
            }
        }

        & .header__img {
            width: 24px;
        }
    }

    & .svg-inline--fa {
        font-size: 24px;
        padding-right: 12px;

    }

    & .inner-right {

        & .inner__avatar {
            cursor: pointer;

            & img {
                width: 40px;
                height: 40px;          
            }
        }
    }

    & .inner__btn {

        @mixin position-center;
        background: #fff;
        border-radius: 40px;
        border: none;
        padding: 0;
        padding: 4px 4px 4px 28px;
        background: #b1c61b;
        margin: 0 3.25em 0 auto;
        width: 197px;

        @media(--tablet) {
            margin: 0;
        }

        @media(--mobile) {
            padding: 0;
            background: none;
            width: auto;
        }

        & .btn__text {
            background: inherit;

            @media(--mobile) {
                display: none;
            }
        }

        & .btn__add {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #c7de1f;
        }
    }

    & .dropdown-toggle {
        position: relative;
        display: flex;
        align-items: center;
    }

    & .header-mobile {
        display: none;

        @media(--desktop) {
            display: block;
            margin-right: 2.25em;
        }

        @media(--tablet) {
            margin-right: 0;
        }

        & .menu-mobile__btn {
            display: block;
            position: relative;
            background: svg-load("../assets/img/icons/bars-solid.svg",fill= #8f8f8f);
            width: 32px;
            height: 32px;
            background-size: 32px 32px;
        } 
    }
}
