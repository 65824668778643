@import "./breakpoints.css";

:root {
    --fontFamily: "Inter";
    --font-size: 14px;
    --main-color: #1a1a1a;
    --alt-color: #84940c;
    --inactive-color: #707070;
    --background: #fafafa;
    --negative-color: #d32f2f;
    --positive-color: #2e7d32;
    --grey: #e0e0e0;
    --dark-grey: #8f8f8f;
    --btn-color: #b1c61b;
    --yellow: #f2c94c;
    --blue: #2f49d3;
    --background-order: #c7de1f;
    --color-order: #474f0b;
}

*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html {

    @media (--mobile) {
        font-size: 14px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--fontFamily);
    color: var(--main-color);
    font-size: var(--font-size);
    font-weight: normal;
    height: 100vh;
    box-sizing: border-box;
    background: var(--background);
}

@supports (-webkit-touch-callout: none) {

    body {
        height: -webkit-fill-available;
    }
}

.setup__input, .form__input {
    transition: all .3s;
}

.setup__input:focus, .setup__input:hover .form__input:focus,.form__input:hover {
    border-color: var( --btn-color);
}

input[type = "number"]::-webkit-outer-spin-button,
input[type = "number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type = "number"] {
    -moz-appearance: textfield;
}
/* переопределение input в chrome */

input:-webkit-autofill, input:focus:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #fff inset;
}

button {
    cursor: pointer;
}


input::-webkit-calendar-picker-indicator {
    display: none;/* remove default arrow */
}

.my-arrow::after {
    content: svg-load("../assets/img/icons/caret-down-solid.svg");
    width: 12px;
    height: 18px;
    margin-left: -40px; 
    padding: .1em;
    pointer-events: none;
    position: absolute;
    margin-top: 15px;

}

button,
button:active,
button:focus {
    outline: none;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

p {
    margin: 0;
}

.wrap {
    max-width: 1128px;
    margin-left: 26%;
    margin-right: 5%;
    box-sizing: border-box;

    @media (--wide) {
        margin-left: 10%;
    }

    @media (--desktop) {
        margin: 0 auto;
        padding: 0 16px;
    }
}

img {
    height: auto;
    width: 100%;
}

h2, h3, h4, h5 {
    margin: 0;
}

ul {
    padding-left: 0;
}

li {
    list-style-type: none;
}

.body-blackout {
    position: fixed;
    z-index: 250;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .65);
    display: none;
}

.is-blacked-out {
    display: block;
}

.cabinet {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;
}

.common {

    &__wrapper {
        margin: 0 auto;
        width: 100%;

    }

    &__main {
        padding-top: 130px;
        padding-bottom: 48px; 

        @media (--tablet) {
            padding-top: 110px;
            padding-bottom: 28px;
        }

        @media (--mobile) {
            padding-top: 100px;
            padding-bottom: 20px;
        }
    }
}

.common__header {

    @mixin border-bottom;
    background: #fff;
    min-height: 72px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
}

.main-btn {
    border: none;
    background: var(--btn-color);
    border-radius: 40px;
    color: #474f0b;
    font-weight: 600;
    font-size: var(--font-size);
    line-height: 18px;
    font-family: "Open Sans";
    text-decoration: none;
    opacity: 1;
    transition: background 200ms linear;
    cursor: pointer;

    &:hover {

        @mixin box-shadow;
        opacity: .8;
        transition: opacity .2s linear;
    }
}

.show {
    display: flex;
}

.card__number {
    font-size: 18px;
    color: var(--alt-color);
    font-weight: 600;

    @media (--wide) {
        font-size: 16px;
    }
}

.order-number {
    font-size: 16px;
    color: var(--alt-color);
    font-weight: 600;
}

.card {

    &__date {
        font-size: var(--font-size);
        color: var(--inactive-color);
        line-height: 20px;

        @media(--mobile) {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__title {
        font-size: 18px;
        color: var(--main-color);
        line-height: 22px;
        font-weight: bold;
    }

    &__subject {
        font-size: 16px;
        line-height: 24px;
        color: var(--inactive-color);
    
        @media (--wide) {
            font-size: var(--font-size);
        }
    }
}

.paperclip {
    width: 24px;
    height: 24px;
}

.btn-payment-full {
    border: none;
    font-weight: 600;
    font-size: var(--font-size);
    line-height: 18px;
    background: inherit;
    color: var(--alt-color);
}

.status-prepayment-text {
    font-size: var(--font-size);
    color: var(--main-color);
    font-weight: 600;
    line-height: 18px;
}

.btn-peyment__paid {
    font-weight: 600;
    font-size: var(--font-size);
    line-height: 18px;
}

.validation {
    position: absolute;
    left: 90%;
    top: 25%;

    .hide {
        display: none;
    }
}

.valid-msg, .valid-email, .valid-name, .valid-surname, .valid {
    color: var(--positive-color);
    font-weight: 600;
    font-size: 16px;
}

.error-msg, .error-email, .error-name, .error-surname, .error {
    color: var(--negative-color);
    font-weight: 600;
}


.iti__selected-flag {
    background: #eee;
    border-radius: 24px 0 0 24px;
}


textarea {
    resize: none;
    overflow: hidden;
}

.overlays {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    opacity: 0;
    background: #333;
    background: var(--inactive-color);
    transition: opacity 1s;

    &-active {

        opacity: 0;

        @media(--desktop) {
            z-index: 200;
            opacity: .4;
            transition: opacity 1s;
            cursor: pointer;
            transition: opacity 1s;
        }
    }
}

.control {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 2px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;

    & input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked ~ .control_indicator {
            background: #fff;
        }

        &:disabled ~ .control_indicator {
            background: #e6e6e6;
            opacity: .6;
            pointer-events: none;
        }
    }

    &_indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #fff;
        border-radius: 2px;
        border: 1px solid #e0e0e0;

        &::after {
            box-sizing: unset;
            content: "";
            position: absolute;
            display: none;
        }
    }
}

/* чекбокс */

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #fff;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #fff;
}


.control input:checked ~ .control_indicator::after {
    display: block;
}

.checked::after {
    left: 7px;
    top: 3px;
    width: 3px;
    height: 9px;
    border: solid #87990b;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator::after {
    border-color: #7b7b7b;
}

.control-checkbox .control_indicator::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4rem;
    height: 4rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #c7df1e;
    border-radius: 3rem;
    opacity: .6;
    z-index: 99999;
    transform: scale(0);
}

@keyframes s-ripple {

    0% {
        transform: scale(0);
    }

    20% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes s-ripple-dup {

    0% {
        transform: scale(0);
    }

    30% {
        transform: scale(1);
    }

    60% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}

.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}

.disabled-button {
    background-color: var(--grey);
    opacity: .8;
    color: var(--main-color);
    transition: background 200ms linear;

    &:hover {
        box-shadow: none;
    }
}

.eye, .eye2 {
    top: 5px;
    position: relative;
}

.fa-eye {
    background: svg-load("../assets/img/icons/open-eye.svg");
    min-width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: block;
    margin-right: 10px;
}

.fa-eye-close {
    background: svg-load("../assets/img/icons/slash-eye.svg");
    min-width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: block;
    margin-right: 10px;
}

.fa-calendar-alt {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 28px;
    top: 25%;
}

.datepicker-cell.selected, .datepicker-cell.selected:hover {
    background-color: var(--btn-color) !important;
    border-radius: 50%;
    color: #474f0b !important;
    font-weight: 600;
}

.datepicker-cell.focused:not(.selected) {
    border-radius: 50%;
}

.datepicker-cell {

    &:hover {
        border-radius: 50%;
    }
}

.iti {
    width: 100%;
}

.iti__country-list {
    width: 350px;
    max-width: 350px;
}

.setup__btn-hide-show {
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
    border: none;
    background: inherit;
    position: absolute;
    right: 5%;
    top: 25%;

    & .fas {
        font-size: 18px;
        width: 23px;
    }
}


.close-button {
    border: none;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    background-color: #eee;
    border-radius: 50%;
    font-size: 25px;
    color: #757575;
    transition: all .1s;

    &:hover {
        box-shadow: 0px 1px 8px rgba(0, 0, 0, .1);
    }
}

.datepicker.active {
    z-index: 310 !important;
}
