@import "./mixins.pcss";

@import "./breakpoints.css";

.menu {
    overflow: hidden;

    @mixin box-shadow;
    z-index: 210;
    width: 264px;
    min-width: 264px;
    transition: all .3s;
    background: #fff;
    height: 100vh;
    position: fixed;
    top: 0;

    @media(--desktop) {
        display: none;
        transition: all .3s;
        transform: translateX(-100%);
        position: static;
    }

    @media (max-width: 320px) {
        position: absolute;
        box-shadow: none;
        width: auto;
    }

    &.hide {
        min-width: 72px;
        width: 72px;
    }

    & .hide .menu__list .menu__text {
        opacity: 0;
    }

    & .bar-button-mobile {
        display: none;

        @media (--desktop) {
            display: block;
            width: 24px;
            position: relative;
        }
    }

    &__sidebar {
        display: block;
        height: auto;
        transition-duration: 1s;
    }

    &__header {
        padding: 24px;
        box-sizing: border-box;
        height: 73px;

        @mixin position-center;

        @mixin border-bottom;
    }

    &__logo {
        cursor: pointer;
        transform: translateX(0%);
        transition: all .3s;

        & img {
            width: 140px;
        }
    }

    & .menu__logo.invisible {
        transition: all .2s;
        transform: translateX(-1000%);
    }

    & .bar-button {
        display: block;
        border: none;
        background-color: #fff;
        min-width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background: svg-load("../assets/img/icons/bars-solid.svg", fill: #8f8f8f );

        @media(--wide) {
            cursor: auto;
        }
    }

    & .bar-button.invisible {
        display: none;
        transition: all .3s ease-out;
    }

    & .but-mob {
        display: none;
    }

    & .close-button.invisible {
        display: none;
        transition: all.3s ease-out;
    }

    & .menu__list {
        margin-top: 35px;

        & ul {
            align-items: baseline;
        }
    }


    & .menu__list > ul {
        display: block;
        list-style: none;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        & .menu__item {
            transition: all .2s linear;
            padding-left: 24px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: var(--font-size);
            line-height: 18px;
            padding-top: 11px;
            padding-bottom: 11px;
            cursor: pointer;
            display: flex;
            align-items: center;
            box-sizing: border-box;

            & .menu__text {
                margin-right: -118px;
                opacity: 1;
                white-space: nowrap;
                transform: translate(28px);
            }
        }

        & .menu__item:hover {
            background: var(--background);
        }

        & .menu__icon {
            width: 24px;
            height: 24px;
            transition: transform .2s ease-out;
        }

        & .menu__item:hover > .menu__icon {
            transform: scale(1.2);
        }

        & .folder {
            background: svg-load("../assets/img/icons/folder-solid.svg", fill= #8f8f8f );
            background-repeat: no-repeat;
        }

        & .credit-card {
            background: svg-load("../assets/img/icons/credit-card-regular.svg" fill= #8f8f8f);
            background-repeat: no-repeat;
            background-size: 24px 24px;
        }

        & .comment {
            background: svg-load("../assets/img/icons/comment-regular.svg", fill= #8f8f8f);
            background-repeat: no-repeat;
            background-size: 24px 24px;
        }

        & .cog {
            background: svg-load("../assets/img/icons/cog.svg" , fill= #8f8f8f);
            background-repeat: no-repeat;
            background-size: 24px 24px;
        }

        & .comments {
            background: svg-load("../assets/img/icons/comments-regular.svg", fill= #8f8f8f);
            background-repeat: no-repeat;
            background-size: 24px 24px;
        }

        & .question-circle {
            background: svg-load("../assets/img/icons/question-circle-regular.svg", fill= #8f8f8f);
            background-repeat: no-repeat;
            background-size: 24px 24px;
        }

        & .active {
            color: var(--alt-color);

            & .folder {
                background: svg-load("../assets/img/icons/folder-solid.svg", fill= #84940c );
                background-repeat: no-repeat;
            }

            & .credit-card {
                background: svg-load("../assets/img/icons/credit-card-regular.svg", fill= #84940c);
                background-repeat: no-repeat;
            }

            & .comment {
                background: svg-load("../assets/img/icons/comment-regular.svg", fill= #84940c);
                background-repeat: no-repeat;
            }

            & .cog {
                background: svg-load("../assets/img/icons/cog.svg" , fill= #84940c);
            }

            & .comments {
                background: svg-load("../assets/img/icons/comments-regular.svg", fill= #84940c);
                background-repeat: no-repeat;
            }

            & .question-circle {
                background: svg-load("../assets/img/icons/question-circle-regular.svg", fill=  #84940c);
                background-repeat: no-repeat;
            }
        }
    }

    & .menu__tooltip {
        display: none;

        @media(--desktop) {
            display: block;
        }
    }

    & .menu__tooltip.visible {
        display: block;
    }
}

.menu-mobile {

    @media(--desktop) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(0);
    }
}

